import { WashingtonPostLogo } from "@washingtonpost/site-components";
import Link from "next/link";
import { useBreakpoints } from "@context/breakpoints";
import { useLayout } from "@context/layout";
import FooterCallout from "./FooterCallout";
import { isObjEmpty } from "@utils";
import SocialIcons from "./SocialIcons";
import {
  FooterWrapper,
  FooterContainer,
  FooterCalloutWrapper,
  FooterTop,
  Menus,
  Nav,
  Logo,
  MenuTitle,
  MenuItem,
  Socket,
  Disclaimer,
  MenuWrap,
  Copyright,
  SocketNav
} from "./styles";
import content from "./content.json";
import PropTypes from "prop-types";

const year = new Date().getFullYear();

const Footer = ({ className }) => {
  const breakpoints = useBreakpoints();
  const { copyright, disclaimer, menus, socketMenu, social } = content;
  const { footerCalloutContent } = useLayout();

  return (
    <FooterWrapper className={className}>
      <FooterContainer>
        {footerCalloutContent && !isObjEmpty(footerCalloutContent) && (
          <FooterCalloutWrapper>
            <FooterCallout
              title={footerCalloutContent?.title}
              cta={footerCalloutContent?.cta}
            />
          </FooterCalloutWrapper>
        )}
        <FooterTop>
          <Logo>
            <Link href="/">
              <a className="inline-flex">
                <WashingtonPostLogo
                  className="fill-white"
                  height="40"
                  width="270"
                />
              </a>
            </Link>
            {social && breakpoints.large && <SocialIcons />}
          </Logo>
          {menus && (
            <Menus>
              {menus?.map((menu, index) => (
                <Nav key={index}>
                  {menu?.title && (
                    <MenuTitle className="font--body">{menu?.title}</MenuTitle>
                  )}
                  {menu.items?.map((menuItem, itemIndex) => (
                    <MenuItem
                      key={itemIndex}
                      className={`white hover-blue ${
                        menuItem?.bold ? "bold" : ""
                      }`}
                    >
                      <Link href={menuItem?.url}>
                        <a>{menuItem?.title}</a>
                      </Link>
                    </MenuItem>
                  ))}
                </Nav>
              ))}
            </Menus>
          )}
          {social && !breakpoints.large && <SocialIcons />}
        </FooterTop>

        <Socket className="font--body-tiny">
          {disclaimer && (
            <Disclaimer>
              {disclaimer?.lineOne && <p>{disclaimer.lineOne}</p>}
              {disclaimer?.lineTwo && <p>{disclaimer.lineTwo}</p>}
            </Disclaimer>
          )}

          <MenuWrap>
            {socketMenu && (
              <SocketNav>
                {socketMenu?.map((item, index) => (
                  <MenuItem key={index}>
                    <Link href={item?.url}>
                      <a className="white" rel="noreferrer" target="_blank">
                        {item?.title}
                      </a>
                    </Link>
                  </MenuItem>
                ))}
              </SocketNav>
            )}

            {copyright && (
              <Copyright>
                {copyright} {year}
              </Copyright>
            )}
          </MenuWrap>
        </Socket>
      </FooterContainer>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string
};

export default Footer;
