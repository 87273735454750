import ReactModal from "react-modal";
import styled from "styled-components";
import PropTypes from "prop-types";

export const navModalAnimationDurationMs = 200;
export const navModalAnimationDuration = navModalAnimationDurationMs / 1000;

export const BaseOverlay = styled.div`
  background-color: rgba(17, 17, 17, 0.25);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity ${navModalAnimationDurationMs}ms ease-in-out;
  z-index: 10; // TODO: use baseNavZIndex (can't because of circular dep now)

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;

export const NavModal = ({
  children,
  className,
  onAfterClose,
  onClose,
  overlayElement,
  show
}) => {
  const Overlay = overlayElement ? overlayElement : BaseOverlay;

  return (
    <ReactModal
      className={className}
      closeTimeoutMS={navModalAnimationDurationMs}
      isOpen={show}
      onAfterClose={onAfterClose}
      onRequestClose={onClose}
      overlayClassName=" " // ensure styled styles are applied
      overlayElement={(props, contentElement) => (
        <Overlay {...props}>{contentElement}</Overlay>
      )}
    >
      {children}
    </ReactModal>
  );
};

NavModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onAfterClose: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  overlayElement: PropTypes.object,
  show: PropTypes.bool.isRequired
};
