import { createGlobalStyle } from "styled-components";

const typography = (theme) => ({
  ".max-w": {
    "max-width": "var(--max-width)"
  },
  ".max-w-md": {
    "max-width": "var(--max-width-md)"
  },
  ".font-sans-serif, .font--body, .font--body-sm": {
    fontFamily: theme.fonts.poppins,
    fontSize: 16,
    lineHeight: 1.5
  },
  ".font--headline, .font--display": {
    fontFamily: theme.fonts.playfair
  },
  // body fonts
  ".font--body-lg": {
    fontFamily: theme.fonts.poppins,
    fontSize: 24,
    lineHeight: 1.5
  },
  ".font--body-md": {
    fontFamily: theme.fonts.poppins,
    fontSize: 18,
    lineHeight: 1.5
  },
  ".font--body-tiny": {
    fontFamily: theme.fonts.poppins,
    fontSize: 12,
    lineHeight: 1.92
  },
  ".font--eyebrow": {
    fontFamily: theme.fonts.playfair,
    fontSize: 16,
    lineHeight: 1.31
  },
  ".font--caption": {
    fontFamily: theme.fonts.poppins,
    fontSize: 14,
    lineHeight: 1.5
  },

  // headings

  ".font--heading-sm": {
    fontFamily: theme.fonts.playfair,
    fontSize: 30,
    fontWeight: 700,
    lineHeight: 1.167
  },
  ".font--heading-md": {
    fontFamily: theme.fonts.playfair,
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 1.125
  },
  ".font--heading-lg": {
    fontFamily: theme.fonts.poppins,
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 1.25
  },
  ".font--heading-xl": {
    fontFamily: theme.fonts.poppins,
    fontSize: 50,
    fontWeight: 700,
    lineHeight: 1.2
  },
  ".font--heading-xxl": {
    fontFamily: theme.fonts.poppins,
    fontSize: 60,
    fontWeight: 700,
    lineHeight: 1.17
  },
  ".font--heading-xxxl": {
    fontFamily: theme.fonts.poppins,
    fontSize: 100,
    fontWeight: 700,
    lineHeight: 1.2
  },
  ".font--heading-emphasis-sm": {
    fontFamily: theme.fonts.playfair,
    fontSize: 30,
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: 1.17
  },
  ".font--heading-emphasis-md": {
    fontFamily: theme.fonts.playfair,
    fontSize: 50,
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: 1.2
  },
  ".font--heading-emphasis-lg": {
    fontFamily: theme.fonts.playfair,
    fontSize: 80,
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: 1.2
  },

  // not in styleguide
  ".font--heading-sm-alt": {
    fontFamily: theme.fonts.poppins,
    fontSize: 30,
    fontWeight: 700,
    lineHeight: 1.33
  },
  ".font--heading-xl em": {
    color: theme.colors.red,
    fontWeight: 400,
    fontFamily: theme.fonts.playfair
  },
  ".font--heading-super": {
    fontFamily: theme.fonts.poppins,
    fontSize: 112,
    fontWeight: 700,
    lineHeight: "100px"
  },
  // not in styleguide

  ".normal": {
    fontWeight: 400
  },
  ".bold": {
    fontWeight: 700
  },
  "fill-white": {
    fill: "white"
  },
  ".text-fill-initial": {
    WebkitTextFillColor: "initial"
  },
  ".text-fill-transparent": {
    WebkitTextFillColor: "transparent"
  },
  ".text-fill-white": {
    WebkitTextFillColor: "white"
  },
  ".text-stroke-0": {
    WebkitTextStrokeWidth: 0
  },
  ".text-stroke-1": {
    WebkitTextStrokeWidth: 1
  },
  ".text-stroke-2": {
    WebkitTextStrokeWidth: 2
  },
  ".ttu": {
    textTransform: "uppercase"
  },
  ".active-text-fill-initial.active": {
    WebkitTextFillColor: "initial"
  }
});

const spacing = () => ({
  ".mx-auto": {
    marginLeft: "auto",
    marginRight: "auto"
  },
  ".px-0": {
    paddingLeft: 0,
    paddingRight: 0
  },
  ".px-xxs": {
    paddingLeft: 4,
    paddingRight: 4
  },
  ".px-xs": {
    paddingLeft: 8,
    paddingRight: 8
  },
  ".px-sm": {
    paddingLeft: 16,
    paddingRight: 16
  },
  ".px-md": {
    paddingLeft: 24,
    paddingRight: 24
  },
  ".px-lg": {
    paddingLeft: 32,
    paddingRight: 32
  },
  ".px-xl": {
    paddingLeft: 48,
    paddingRight: 48
  },
  ".px-xxl": {
    paddingLeft: 64,
    paddingRight: 64
  },
  ".ml-auto": {
    marginLeft: "auto"
  },
  ".mr-auto": {
    marginRight: "auto"
  },

  ".py-0": {
    paddingBottom: 0,
    paddingTop: 0
  },
  ".py-xxs": {
    paddingBottom: 4,
    paddingTop: 4
  },
  ".py-xs": {
    paddingBottom: 8,
    paddingTop: 8
  },
  ".py-sm": {
    paddingBottom: 16,
    paddingTop: 16
  },
  ".py-md": {
    paddingBottom: 24,
    paddingTop: 24
  },
  ".py-lg": {
    paddingBottom: 32,
    paddingTop: 32
  },
  ".py-xl": {
    paddingBottom: 48,
    paddingTop: 48
  },
  ".py-xxl": {
    paddingBottom: 64,
    paddingTop: 64
  }
});

export const GlobalStyle = createGlobalStyle(({ theme }) => ({
  ":root": {
    "--max-width-md": "1050px",
    "--max-width": "1440px",
    "--space-md": "1rem",
    "--space-lg": "2rem",
    "--headline-alternate": "80px"
  },
  body: {
    backgroundColor: theme.colors.creamLight
  },
  "body, body *": {
    boxSizing: "border-box"
  },

  // border colors
  ".bc-black": {
    borderColor: theme.colors.black
  },
  ".bc-gray2": {
    borderColor: theme.colors.gray2
  },
  ".bc-gray3": {
    borderColor: theme.colors.gray3
  },
  ".bc-gray4": {
    borderColor: theme.colors.gray4
  },
  ".bc-gray5": {
    borderColor: theme.colors.gray5
  },
  ".bc-gray6": {
    borderColor: theme.colors.gray6
  },
  ".bc-gray7": {
    borderColor: theme.colors.gray7
  },
  ".bc-green": {
    borderColor: theme.colors.green
  },
  ".bc-red": {
    borderColor: theme.colors.red
  },

  // background colors
  ".bg-black": {
    backgroundColor: theme.colors.black
  },
  ".bg-cream": {
    backgroundColor: theme.colors.cream
  },
  ".bg-gray3": {
    backgroundColor: theme.colors.gray3
  },
  ".bg-green": {
    backgroundColor: theme.colors.green
  },
  ".bg-white": {
    backgroundColor: theme.colors.white
  },
  ".bg-pinkish": {
    backgroundColor: "rgba(231, 49, 6, 0.3)"
  },
  // text colors
  ".black": {
    color: theme.colors.black
  },
  ".gray5": {
    color: theme.colors.gray5
  },
  ".gray6": {
    color: theme.colors.gray6
  },
  ".gray7": {
    color: theme.colors.gray7
  },
  ".green": {
    color: theme.colors.green
  },
  ".green-dark": {
    color: theme.colors.greenDark
  },
  ".white": {
    color: theme.colors.white
  },

  ".hover-bc-green:hover": {
    borderColor: theme.colors.green
  },
  ".hover-bg-blue-25:hover": {
    backgroundColor: theme.colors["blue-25"]
  },
  ".hover-bg-green:hover": {
    backgroundColor: theme.colors.green
  },
  ".hover-bg-transparent:hover": {
    backgroundColor: "transparent"
  },
  ".hover-bold:hover": {
    fontWeight: 700
  },
  ".hover-green:hover": {
    color: theme.colors.green
  },
  ".hover-red:hover": {
    color: theme.colors.red
  },
  ".hover-blue:hover": {
    color: theme.colors.blue
  },
  ".hover-blue-light:hover": {
    color: theme.colors.blueLight
  },
  ".active-bold.active": {
    fontWeight: 700
  },
  ".active-green.active": {
    color: theme.colors.green
  },
  ".mw-768": {
    maxWidth: 768
  },
  ".mw-1024": {
    maxWidth: 1024
  },
  ".red": {
    color: theme.colors.red
  },
  ".red-feedback": {
    color: theme.colors.redFeedback
  },
  [`@media ${theme.breakpoints.notSmall}`]: {
    "flex-1-ns": {
      flex: 1
    }
  },
  [`@media ${theme.breakpoints.large}`]: {
    ":root": {
      "--headline-alternate": "125px"
    },
    ".h-auto-l": {
      height: "auto"
    },
    ".font--heading-xl": {
      fontSize: "50px !important"
    }
  },
  ".ReactModal__Body--open": {
    overflow: "hidden"
  },
  ...spacing(),
  ...typography(theme)
}));
