import { useRouter } from "next/router";
import clsx from "clsx";
import PropTypes from "prop-types";

export const SectionName = ({ children, url }) => {
  const { asPath } = useRouter();

  return (
    <span className={clsx({ "text-stroke-1": asPath.startsWith(url) })}>
      {children}
    </span>
  );
};

SectionName.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired
};
