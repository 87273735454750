import Link from "next/link";
import { default as Facebook } from "@images/social-facebook.svg";
import { default as Twitter } from "@images/social-twitter.svg";
import { default as LinkedIn } from "@images/social-linkedin.svg";
import { Social, SocialItem } from "../styles";
import { social } from "../content.json";

const SocialIcons = () => {
  return (
    <Social>
      {social?.facebook && (
        <SocialItem>
          <Link href={social.facebook}>
            <a
              className="white hover-blue-light"
              rel="noreferrer"
              target="_blank"
            >
              <Facebook />
            </a>
          </Link>
        </SocialItem>
      )}
      {social?.twitter && (
        <SocialItem>
          <Link href={social.twitter}>
            <a
              className="white hover-blue-light"
              rel="noreferrer"
              target="_blank"
            >
              <Twitter />
            </a>
          </Link>
        </SocialItem>
      )}
      {social?.linkedIn && (
        <SocialItem>
          <Link href={social.linkedIn}>
            <a
              className="white hover-blue-light"
              rel="noreferrer"
              target="_blank"
            >
              <LinkedIn />
            </a>
          </Link>
        </SocialItem>
      )}
    </Social>
  );
};

export default SocialIcons;
