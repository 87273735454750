import PropTypes from "prop-types";
import Link from "next/link";
import { MenuList, MenuLink } from "./Menu";

export const ReachMenu = ({ content, onSelectItem }) => {
  return (
    <MenuList>
      {content.items.map((item) => (
        <li key={item.url}>
          <Link href={item.url} passHref>
            <MenuLink onClick={onSelectItem}>{item.name}</MenuLink>
          </Link>
        </li>
      ))}
    </MenuList>
  );
};

ReachMenu.propTypes = {
  content: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  onSelectItem: PropTypes.func.isRequired
};
