import styled from "styled-components";
import PropTypes from "prop-types";
import { Stack } from "@washingtonpost/site-components";
import { LinkButton } from "@primitives/buttons";
import { useBreakpoints } from "@context/breakpoints";
import { theme } from "@src/theme";

const FooterWrapper = styled.div`
  align-items: center;
  background-color: ${theme.colors.white};
  display: flex;
  justify-content: center;
  min-height: 500px;
  padding-left: 4rem;
  position: relative;

  @media ${theme.breakpoints.large} {
    justify-content: flex-end;
  }
`;

const FooterInner = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 340px;

  @media ${theme.breakpoints.notSmall} {
    max-width: 860px;
  }
`;

const FooterContent = styled.div`
  max-width: 24em;
  transform: translateY(-4rem);

  @media ${theme.breakpoints.notSmall} {
    max-width: 34em;
    transform: translate(0);
  }

  @media ${theme.breakpoints.large} {
    max-width: 44em;
  }

  @media ${theme.breakpoints.max} {
    transform: translateY(-2rem);
  }
`;

const DecorationWrapper = styled.div`
  left: 50%;
  margin-left: -50vw;
  min-width: 100vw;
  position: absolute;
  height: 100%;
  overflow-x: hidden;
  pointer-events: none;
  top: -6rem;

  @media ${theme.breakpoints.notSmall} {
    top: 0;
  }

  @media ${theme.breakpoints.large} {
    left: 0;
    margin-left: 0;
    min-width: 100%;
    overflow: visible;
  }
`;

const Decoration = styled.svg`
  color: ${theme.colors.red};
  height: calc(100% - 1rem);
  left: -15rem;
  position: absolute;
  pointer-events: none;
  top: 0;

  @media ${theme.breakpoints.notSmall} {
    height: calc(100% + 4rem);
    left: -15rem;
    top: -6.5rem;
  }

  @media ${theme.breakpoints.large} {
    left: -14rem;
  }

  @media ${theme.breakpoints.max} {
    left: auto;
    max-width: calc(100% - 4rem);
    right: 0;
    top: -9rem;
    width: auto;
  }
`;

const Heading = styled.h3(({ theme }) => ({
  color: theme.colors.red,
  fontFamily: theme.fonts.playfair
}));

const FooterCallout = ({ title, cta }) => {
  const breakpoints = useBreakpoints();
  const headingClass = breakpoints.large
    ? "font--heading-xl pr-xxl"
    : "font--heading-sm pr-xxl";

  return (
    <FooterWrapper>
      <FooterInner>
        <FooterContent>
          <Stack space="sm">
            {title && (
              <Heading className={headingClass}>
                <em>{title}</em>
              </Heading>
            )}
            {cta && <LinkButton href={cta?.url}>{cta?.title}</LinkButton>}
          </Stack>
        </FooterContent>
      </FooterInner>

      <DecorationWrapper>
        <Decoration
          width="1263"
          height="562"
          viewBox="0 0 1263 562"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M269 308V427C269 501.006 328.994 561 403 561H1262.5"
            stroke="currentColor"
          />
          <path d="M269 313L269 134" stroke="currentColor" />
          <path
            d="M1 135C1 60.9938 60.9938 1 135 1C209.006 1 269 60.9938 269 135V275"
            stroke="currentColor"
          />
        </Decoration>
      </DecorationWrapper>
    </FooterWrapper>
  );
};

FooterCallout.propTypes = {
  title: PropTypes.string,
  cta: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })
};

export default FooterCallout;
