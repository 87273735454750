import Head from "next/head";
import Modal from "react-modal";
import PropTypes from "prop-types";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { BreakpointsProvider } from "@context/breakpoints/";
import { LayoutProvider } from "@context/layout";
import Container from "@primitives/container";
import Header from "./Header";
import Footer from "./Footer";
import { GridLines } from "@styles/general";

Modal.setAppElement("#__next");
gsap.registerPlugin(ScrollTrigger);

const Layout = ({ children }) => {
  return (
    <>
      <Head>
        <title>Washington Post Media Kit</title>
      </Head>
      <main id="main">
        <BreakpointsProvider>
          <LayoutProvider>
            <Header />
            <div style={{ overflowX: "hidden" }}>
              <Container>
                <GridLines>
                  <span></span>
                  <span></span>
                  <span></span>
                </GridLines>
                {children}
              </Container>
              <Footer />
            </div>
          </LayoutProvider>
        </BreakpointsProvider>
      </main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.object
};

export default Layout;
