import styled from "styled-components";
import {
  NavModal as BaseNavModal,
  navModalAnimationDuration
} from "@components/modal/NavModal";
import { LinkButton as BaseLinkButton } from "@primitives/buttons";
import { Chevron as BaseChevron } from "./Chevron";
import { Logo as BaseLogo } from "../Logo";

export const navHeight = 103;
export const baseNavZIndex = 10;

export const Header = styled.header`
  --background-opacity: 0;
  --button-background-color: ${({ theme, variant }) =>
    variant === "light" ? theme.colors.white : theme.colors.black};
  --button-text-color: ${({ theme, variant }) =>
    variant === "light" ? theme.colors.black : theme.colors.white};
  --text-color: ${({ theme, variant }) =>
    variant === "light" ? theme.colors.white : theme.colors.black};
`;

export const Nav = styled.nav`
  align-items: center;
  color: var(--text-color);
  height: ${navHeight}px;
  display: flex;
  justify-content: space-between;
  left: 50%;
  max-width: 1440px;
  padding: 0 48px;
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  z-index: ${baseNavZIndex + 1};
`;

export const NavBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  height: ${navHeight}px;
  left: 0;
  opacity: var(--background-opacity);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${baseNavZIndex};
`;

export const NavModal = styled(BaseNavModal)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  max-height: 100vh;
  max-width: 1440px;
  overflow: auto;
  z-index: ${baseNavZIndex + 1};
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: none;
  }
`;

export const Menu = styled.ul`
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media ${({ theme }) => theme.breakpoints.max} {
    margin-left: 84px;
  }
`;

export const MenuItem = styled.li`
  ${({ theme }) => theme.fonts.caption}
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin-right: 36px;
  padding: 8px 0;
  position: relative;

  &:hover {
    -webkit-text-stroke-width: 1px;

    &::after {
      width: 100%;
    }
  }

  &::after {
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.red};
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    transition: width ${navModalAnimationDuration}s linear;
    width: 0%;
  }

  > svg {
    margin-left: 8px;
  }
`;

export const Logo = styled(BaseLogo)`
  fill: var(--text-color);
`;

export const Chevron = styled(BaseChevron)`
  transition: transform ${navModalAnimationDuration}s linear;
  transform: ${({ active }) => (active ? "rotate(180deg)" : "rotate(0deg)")};
`;

export const PageLink = styled.a`
  color: inherit;
`;

export const LinkButton = styled(BaseLinkButton)`
  background-color: var(--button-background-color);
  border-color: var(--button-background-color);
  color: var(--button-text-color);

  @media ${({ theme }) => theme.breakpoints.max} {
    margin-left: auto;
  }
`;
