import styled from "styled-components";
import Container from "@primitives/container";
import { theme } from "@src/theme";

export const FooterWrapper = styled.footer`
  background-color: ${theme.colors.black};
  color: ${theme.colors.white};
  position: relative;
  z-index: 3;
`;

export const FooterContainer = styled(Container)``;

export const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 2rem 0;

  @media ${theme.breakpoints.large} {
    align-items: flex-end;
    flex-direction: row;
  }
`;

export const FooterCalloutWrapper = styled.div`
  margin-bottom: -10rem;
  margin-top: calc(10rem + 10rem);
  transform: translateY(-10rem);

  @media ${theme.breakpoints.large} {
    margin-top: calc(10rem + 18rem);
  }
`;

export const Logo = styled.div`
  @media ${theme.breakpoints.large} {
    min-width: 360px;
  }
`;

export const Menus = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 600px;

  @media ${theme.breakpoints.large} {
    margin-left: auto;
  }
`;

export const SocketNav = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;

  li:not(:last-child) {
    margin-right: 0.75rem;
    padding-right: 0.75rem;

    &::after {
      content: "|";
      color: currentColor;
      position: absolute;
      right: 0;
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 2rem;
  min-width: 165px;

  li {
    margin-bottom: 1rem;
  }
`;

export const MenuTitle = styled.h3`
  margin-bottom: 1rem;
`;

export const MenuItem = styled.li`
  position: relative;

  @media ${theme.breakpoints.large} {
    margin: 0;
  }

  a {
    color: currentColor;

    &:hover {
      color: ${theme.colors.blueLight};
      text-decoration: underline;
      -webkit-text-stroke-width: 1px;
    }
  }

  &:hover {
    a {
      color: ${theme.colors.blueLight};
    }
  }
`;

export const Socket = styled.div`
  display: flex;
  color: ${theme.colors.white};
  flex-direction: row;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 1rem;

  @media ${theme.breakpoints.large} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0;
  }
`;

export const Disclaimer = styled.div`
  margin-top: ${theme.spacing.md};

  @media ${theme.breakpoints.large} {
    margin-top: 0;
  }
`;

export const Copyright = styled.span`
  margin-bottom: 2rem;

  @media ${theme.breakpoints.large} {
    margin-bottom: 0;
  }
`;

export const MenuWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.md};

  @media ${theme.breakpoints.large} {
    align-items: flex-end;
    margin-top: 0;
  }
`;

export const Social = styled.ul`
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;
`;

export const SocialItem = styled.li`
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;
