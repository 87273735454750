import React from "react";
import { useBreakpoints } from "@context/breakpoints";
import { MenuNav } from "./menuNav";
import { HorizontalNav } from "./horizontalNav";

const Header = () => {
  const breakpoints = useBreakpoints();

  if (breakpoints.large) {
    return <HorizontalNav />;
  }

  return <MenuNav />;
};

export default Header;
