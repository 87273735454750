import styled from "styled-components";
import Link from "next/link";
import PropTypes from "prop-types";
import { useBreakpoints } from "@context/breakpoints";
import { theme } from "@src/theme";
import { NavCard } from "@primitives/card/NavCard";
import { useEffect } from "react";
import gsap from "gsap";
import { useRef } from "react";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 33% 1fr;
  opacity: 0;

  @media ${theme.breakpoints.max} {
    column-gap: 48;
    grid-template-columns: 300px 1fr 348px;
    grid-template-rows: 1fr;
  }
`;

const Heading = styled.h2`
  ${theme.fonts.headingLarge}
  padding-left: 48px;
  margin-top: 156px;
  width: 295px;
`;

const Items = styled.ul`
  column-gap: 30px;
  display: grid;
  list-style: none;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, auto);
  margin: 138px 0 0;
  padding: 0 0 60px 0;
  @media ${theme.breakpoints.max} {
    column-gap: 48px;
    padding-bottom: 104px;
  }
`;

const ItemLink = styled.a`
  ${theme.fonts.bodyLarge}
  color: ${theme.colors.black};
  display: block;
  font-weight: 700;
  padding: 18px 28px;
  &:hover {
    background-color: ${theme.colors.blueLight};
  }
`;

const CardContainer = styled.div`
  border-top: 1px solid ${theme.colors.cream};
  display: flex;
  grid-column: 1 / span 2;
  min-height: 305px;

  &:hover {
    background-color: ${theme.colors.black};
  }

  @media ${theme.breakpoints.max} {
    border-left: 1px solid ${theme.colors.cream};
    border-top: 0;
    grid-column: auto;
  }
`;

export const ReachMenu = ({ content }) => {
  const ref = useRef();
  const breakpoint = useBreakpoints();
  const { card, heading, items } = content;

  useEffect(() => {
    gsap.to(ref.current, { opacity: 1 });
  }, []);

  return (
    <Grid ref={ref}>
      <Heading>{heading}</Heading>
      <Items>
        {items.map((item) => (
          <li key={item.url}>
            <Link href={item.url} passHref>
              <ItemLink>{item.name}</ItemLink>
            </Link>
          </li>
        ))}
      </Items>
      <CardContainer>
        <NavCard
          imageUrl={breakpoint.max ? card.imageUrl : card.tabletImageUrl}
          eyebrow={card.eyebrow}
          title={card.title}
          url={card.url}
        />
      </CardContainer>
    </Grid>
  );
};

ReachMenu.propTypes = {
  content: PropTypes.shape({
    card: PropTypes.shape({
      eyebrow: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      tabletImageUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired,
    heading: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
};
