import Link from "next/link";
import { useState } from "react";
import { ReachMenu } from "./ReachMenu";
import { CapabilitiesMenu } from "./CapabilitiesMenu";
import content from "../content.json";
import { useLayout } from "@context/layout";
import {
  Header,
  Nav,
  NavBackground,
  NavModal,
  Menu,
  MenuItem,
  Logo,
  Chevron,
  PageLink,
  LinkButton
} from "./styles";
import { SectionName } from "./SectionName";
import { useAnimations } from "./useAnimations";
import { useCallback } from "react";

export const HorizontalNav = () => {
  const { navVariant } = useLayout();
  const [overrideButtonVariant, setOverrideButtonVariant] = useState(false);

  const onContentAnimationComplete = useCallback(
    () => setOverrideButtonVariant(true),
    []
  );
  const onContentAnimationReverseComplete = useCallback(
    () => setOverrideButtonVariant(false),
    []
  );

  const {
    headerRef,
    reverseContentAnimation,
    scrolled,
    startContentAnimation
  } = useAnimations({
    navVariant,
    onContentAnimationComplete,
    onContentAnimationReverseComplete
  });

  const [showSubMenu, setShowSubMenu] = useState(false);
  const [subMenuType, setSubMenuType] = useState(null);

  const onReachMouseEnter = () => {
    setSubMenuType("reach");
    setShowSubMenu(true);

    if (navVariant === "light" && !scrolled) {
      startContentAnimation();
    }
  };

  const onCapabilitiesMouseEnter = () => {
    setSubMenuType("capabilities");
    setShowSubMenu(true);

    if (navVariant === "light" && !scrolled) {
      startContentAnimation();
    }
  };

  const onSubMenuClose = () => {
    setShowSubMenu(false);

    if (navVariant === "light" && !scrolled) {
      reverseContentAnimation();
    }
  };

  const onAfterSubMenuClose = () => {
    setSubMenuType(null);
  };

  const {
    blog,
    capabilities,
    editorialExpansion,
    getInTouch,
    reach,
    successStories
  } = content;

  return (
    <Header
      onClickCapture={onSubMenuClose}
      ref={headerRef}
      variant={navVariant}
    >
      <NavModal
        onAfterClose={onAfterSubMenuClose}
        onClose={onSubMenuClose}
        show={showSubMenu}
      >
        {subMenuType === "reach" && <ReachMenu content={reach} />}
        {subMenuType === "capabilities" && (
          <CapabilitiesMenu content={capabilities} />
        )}
      </NavModal>
      <NavBackground />
      <Nav>
        <Link href="/">
          <a className="inline-flex">
            <Logo height={24} width={163} />
          </a>
        </Link>
        <Menu>
          <MenuItem onMouseEnter={onReachMouseEnter}>
            <SectionName url={reach.url}>{reach.name}</SectionName>
            <Chevron active={showSubMenu && subMenuType === "reach"} />
          </MenuItem>
          <MenuItem onMouseEnter={onCapabilitiesMouseEnter}>
            <SectionName url={capabilities.url}>
              {capabilities.name}
            </SectionName>
            <Chevron active={showSubMenu && subMenuType === "capabilities"} />
          </MenuItem>
          <MenuItem>
            <Link href={editorialExpansion.url} passHref>
              <PageLink>
                <SectionName url={editorialExpansion.url}>
                  {editorialExpansion.name}
                </SectionName>
              </PageLink>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href={successStories.url} passHref>
              <PageLink>
                <SectionName url={successStories.url}>
                  {successStories.name}
                </SectionName>
              </PageLink>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href={blog.url} passHref>
              <PageLink>
                <SectionName url={blog.url}>{blog.name}</SectionName>
              </PageLink>
            </Link>
          </MenuItem>
        </Menu>
        <LinkButton
          alternate={navVariant === "light" && !overrideButtonVariant}
          href={getInTouch.url}
        >
          {getInTouch.name}
        </LinkButton>
      </Nav>
    </Header>
  );
};
