import styled from "styled-components";
import Link from "next/link";
import PropTypes from "prop-types";
import { DiagonalArrow as BaseDiagonalArrow } from "../DiagonalArrow";
import { theme } from "@src/theme";
import { useEffect, useRef } from "react";
import gsap from "gsap";

const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 22px;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  padding: 138px 48px 0;

  @media ${theme.breakpoints.max} {
    grid-gap: 48px;
    grid-template-columns: repeat(4, minmax(300px, 1fr));
  }
`;

const FirstColumn = styled.div`
  grid-row: span 3;
  @media ${theme.breakpoints.max} {
    grid-row: span 2;
  }
`;

const Heading = styled.h2`
  ${theme.fonts.headingLarge}
  margin-top: 18px;
  width: 250px;
`;

const ItemLink = styled.a`
  color: ${theme.colors.black};
  display: block;
  padding: 36px 26px 25px;
  &:hover {
    background-color: ${theme.colors.blueLight};
  }
`;

const ItemTitle = styled.h3`
  ${theme.fonts.bodyLarge}
  font-weight: 700
`;

const ItemBody = styled.p`
  ${theme.fonts.caption}
  margin-top: 18px;
`;

const Specs = styled.ul`
  border-top: 1px solid ${theme.colors.cream};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style: none;
  margin: 56px 0 0;
  padding: 0;
  @media ${theme.breakpoints.max} {
    margin-top: 80px;
  }
`;

const Spec = styled.li`
  border-left: 1px solid ${theme.colors.cream};
  &:first-child {
    border-left: none;
  }
`;

const SpecLink = styled.a`
  ${theme.fonts.bodySmall};
  align-items: center;
  color: ${theme.colors.black};
  display: flex;
  padding: 20px 38px;
  &:hover {
    background-color: ${theme.colors.blueLight};
  }
`;

const DiagonalArrow = styled(BaseDiagonalArrow)`
  margin-left: 16px;
`;

export const CapabilitiesMenu = ({ content }) => {
  const ref = useRef();

  useEffect(() => {
    gsap.to(ref.current, { opacity: 1 });
  }, []);

  return (
    <div ref={ref} className="o-0">
      <Grid>
        <FirstColumn>
          <Heading>{content.heading}</Heading>
        </FirstColumn>
        {content.items.map((item) => (
          <Link href={item.url} passHref key={item.url}>
            <ItemLink target={item.isNewTab ? "_blank" : "_self"}>
              <ItemTitle>{item.title}</ItemTitle>
              <ItemBody>{item.body}</ItemBody>
            </ItemLink>
          </Link>
        ))}
      </Grid>

      <Specs>
        {content.specs.map((spec) => (
          <Spec key={spec.url}>
            <Link href={spec.url} passHref>
              <SpecLink rel="noreferrer" target="_blank">
                <span>{spec.name}</span>
                <DiagonalArrow />
              </SpecLink>
            </Link>
          </Spec>
        ))}
      </Specs>
    </div>
  );
};

CapabilitiesMenu.propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        body: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    ).isRequired,
    specs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
};
