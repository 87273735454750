import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Hamburger, LinkButton } from "@primitives/buttons";
import { Logo } from "../Logo";
import Link from "next/link";
import {
  NavModal as BaseNavModal,
  BaseOverlay
} from "@components/modal/NavModal";
import content from "../content.json";
import { baseNavZIndex } from "../horizontalNav/styles";
import { navModalAnimationDurationMs } from "@components/modal/NavModal";
import { CapabilitiesMenu } from "./CapabilitiesMenu";
import { ReachMenu } from "./ReachMenu";

const navHeight = 69;

const ModalOverlay = styled(BaseOverlay)`
  top: ${navHeight}px;
`;

const NavModal = styled(BaseNavModal)`
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  overflow: hidden auto;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: none;
  }
`;

const Container = styled.div`
  display: flex;
  min-height: 100%;
  transition: transform ${navModalAnimationDurationMs}ms ease-in-out;
  transform: translate3d(
    ${({ showSecondary }) => (showSecondary ? "-100vw" : "0px")},
    0px,
    0px
  );
`;

const MainMenu = styled.ul`
  flex: 1 0 100vw;
  list-style: none;
  margin: 0;
  padding: 24px 24px 0;
`;

const MenuItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.red};
  &:last-child {
    border-bottom: none;
  }
`;

const MenuLink = styled.a`
  ${({ theme }) => theme.fonts.bodyLarge}
  color: ${({ theme }) => theme.colors.black};
  display: block;
  font-weight: 700;
  padding: 16px 0;
`;

const MenuButton = styled.button`
  ${({ theme }) => theme.fonts.bodyLarge}
  align-items: center;
  border: 0;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  padding: 16px 0;
  text-align: left;
  width: 100%;
`;

const RightChevron = (props) => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.59 16.59L13.17 12L8.59 7.41L10 6L16 12L10 18L8.59 16.59Z"
      fill="currentColor"
    />
  </svg>
);

const GetInTouchButton = styled(LinkButton)`
  margin-top: 32px;
  padding-bottom: 24px;
  padding-top: 24px;
  width: 100%;
`;

const SecondaryNav = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: ${navHeight}px;
  justify-content: space-between;
  left: 0;
  opacity: ${({ show }) => (show ? 1 : 0)};
  padding: 20px 24px;
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity ${navModalAnimationDurationMs}ms ease-in-out;
`;

const SecondaryMenu = styled.div`
  background-color: ${({ theme }) => theme.colors.creamLight};
  flex: 1 0 100vw;
`;

const BackButton = styled.button`
  border: 0;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  display: inline-flex;
  padding: 8px 0;
`;

const BackArrow = (props) => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 23 14"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2 7L22.4444 7" stroke="currentColor" strokeWidth="2" />
    <path d="M8 1L2 7L8 13" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const ModalMenu = ({ onClose, show }) => {
  const [showSecondaryMenu, setShowSecondaryMenu] = useState(false);
  const [showReachMenu, setShowReachMenu] = useState(false);
  const [showCapabilitiesMenu, setShowCapabilitiesMenu] = useState(false);

  const {
    blog,
    capabilities,
    editorialExpansion,
    getInTouch,
    reach,
    successStories
  } = content;

  const resetMenus = () => {
    setShowReachMenu(false);
    setShowCapabilitiesMenu(false);
  };

  const onShowSecondaryMenu = (type) => {
    resetMenus();
    setShowSecondaryMenu(true);

    if (type === "reach") {
      setShowReachMenu(true);
    } else if (type === "capabilities") {
      setShowCapabilitiesMenu(true);
    }
  };

  const onCloseSecondaryMenu = () => {
    setShowSecondaryMenu(false);
  };

  const onAfterClose = () => {
    resetMenus();
    setShowSecondaryMenu(false);
  };

  return (
    <NavModal
      onAfterClose={onAfterClose}
      onClose={onClose}
      overlayElement={ModalOverlay}
      show={show}
    >
      <SecondaryNav show={showSecondaryMenu}>
        <BackButton onClick={onCloseSecondaryMenu}>
          <BackArrow />
        </BackButton>
        <h3 className="font--body-lg">
          {showReachMenu ? reach.name : capabilities.name}
        </h3>
        <Hamburger onClick={onClose} mode="close" />
      </SecondaryNav>

      <Container showSecondary={showSecondaryMenu}>
        <MainMenu>
          <MenuItem>
            <MenuButton onClick={() => onShowSecondaryMenu("reach")}>
              {reach.name}
              <RightChevron />
            </MenuButton>
          </MenuItem>
          <MenuItem>
            <MenuButton onClick={() => onShowSecondaryMenu("capabilities")}>
              {capabilities.name}
              <RightChevron />
            </MenuButton>
          </MenuItem>
          <MenuItem>
            <Link href={editorialExpansion.url} passHref>
              <MenuLink onClick={onClose}>{editorialExpansion.name}</MenuLink>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href={successStories.url} passHref>
              <MenuLink onClick={onClose}>{successStories.name}</MenuLink>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href={blog.url} passHref>
              <MenuLink onClick={onClose}>{blog.name}</MenuLink>
            </Link>
          </MenuItem>
          <li>
            <GetInTouchButton href={getInTouch.url} onClick={onClose}>
              {getInTouch.name}
            </GetInTouchButton>
          </li>
        </MainMenu>
        <SecondaryMenu>
          {showReachMenu && (
            <ReachMenu content={reach} onSelectItem={onClose} />
          )}
          {showCapabilitiesMenu && (
            <CapabilitiesMenu content={capabilities} onSelectItem={onClose} />
          )}
        </SecondaryMenu>
      </Container>
    </NavModal>
  );
};

ModalMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.cream};
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: ${baseNavZIndex};
`;

const Nav = styled.nav`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
`;

export const MenuNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <Header>
      <Nav>
        <Link href="/">
          <a className="inline-flex">
            <Logo height={28} width={189} />
          </a>
        </Link>
        <Hamburger
          onClick={toggleMenu}
          mode={menuOpen ? "close" : "hamburger"}
        />
      </Nav>
      <ModalMenu onClose={toggleMenu} show={menuOpen} />
    </Header>
  );
};
