/* eslint-disable no-undef */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { RecruitingMessage } from "@washingtonpost/site-components/core/recruiting-message";
import { GlobalStyle } from "@components/GlobalStyle";
import Layout from "@components/Layout";
import { theme } from "@src/theme";
import "@washingtonpost/site-components/css/index.css";

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const isPrivacyOptOut = /wp_usp=1[-YN]Y[-YN]/.test(document.cookie);

      if (!isPrivacyOptOut) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://js.adsrvr.org/up_loader.1.1.0.js";
        script.onload = function () {
          ttd_dom_ready(function () {
            if (typeof TTDUniversalPixelApi === "function") {
              var universalPixelApi = new TTDUniversalPixelApi();
              universalPixelApi.init(
                "dwxfi70",
                ["tc9mwyo"],
                "https://insight.adsrvr.org/track/up"
              );
            }
          });
        };
        document.body.appendChild(script);
      }
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>
        <Component {...pageProps} />
      </Layout>
      <RecruitingMessage />
    </ThemeProvider>
  );
}

export default MyApp;

MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.object
};
