import PropTypes from "prop-types";

export const Chevron = ({ className }) => (
  <svg
    width="13"
    height="9"
    viewBox="0 0 13 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1.41873 0.657673L6.00728 5.23913L10.5987 0.660583L12.0083 2.07103L6.00638 8.06913L0.00828113 2.06723L1.41873 0.657673Z"
      fill="currentColor"
    />
  </svg>
);

Chevron.propTypes = {
  className: PropTypes.string
};
