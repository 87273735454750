import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { RotatableSvg } from "@primitives/rotatableSvg";
import { theme } from "@src/theme";

const Arrow = ({ direction = "right", ...props }) => (
  <RotatableSvg
    direction={direction}
    fill="currentColor"
    height="8"
    viewBox="0 0 13 8"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3536 4.35356C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645L9.17157 0.464468C8.97631 0.269205 8.65973 0.269205 8.46447 0.464468C8.2692 0.65973 8.2692 0.976312 8.46447 1.17157L11.2929 4L8.46447 6.82843C8.2692 7.02369 8.2692 7.34027 8.46447 7.53554C8.65973 7.7308 8.97631 7.7308 9.17157 7.53554L12.3536 4.35356ZM-8.74228e-08 4.5L12 4.5L12 3.5L8.74228e-08 3.5L-8.74228e-08 4.5Z"
      fill="currentColor"
    />
  </RotatableSvg>
);

Arrow.propTypes = {
  direction: RotatableSvg.propTypes.direction
};

export const redVariantHoverStyles = `
  background-color: ${theme.colors.red};
  color: ${theme.colors.black};
`;

const redStyles = `
  border: 1px solid ${theme.colors.red};
  color: ${theme.colors.red};

  &:hover:not(:disabled) {
    ${redVariantHoverStyles}
  }
`;

const Button = styled.button`
  align-items: center;
  border: 1px solid ${theme.colors.black};
  border-radius: 100%;
  color: ${theme.colors.black};
  cursor: pointer;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  padding: 0;
  width: 50px;

  &:hover {
    border: 1px solid ${theme.colors.red};
    color: ${theme.colors.red};
  }

  ${({ variant }) =>
    variant === "red"
      ? css`
          ${redStyles}
        `
      : ""}

  &:focus {
    position: relative;
    &::after {
      border-radius: 2px;
      bottom: -1px;
      content: "";
      left: -1px;
      outline: 1px solid ${theme.colors.blue};
      position: absolute;
      right: -1px;
      top: -1px;
    }
  }

  &:active {
    outline: none;
  }

  &:disabled {
    border: 1px solid ${theme.colors.gray6};
    color: ${({ theme }) => theme.colors.gray6};
    cursor: default;
  }
`;

export const CircledArrowButton = ({
  direction,
  variant = "default",
  ...props
}) => (
  <Button variant={variant} {...props}>
    <Arrow direction={direction} />
  </Button>
);

CircledArrowButton.propTypes = {
  direction: Arrow.propTypes.direction,
  variant: PropTypes.oneOf(["default", "red"])
};
