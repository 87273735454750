import { CardArrow, CardEyebrow, CardTitle } from "./styles";
import Link from "next/link";
import styled from "styled-components";
import { theme } from "@src/theme";
import { redVariantHoverStyles } from "@primitives/buttons/CircledArrowButton";
import PropTypes from "prop-types";
import Image from "@primitives/image";

const Clickable = styled.a`
  color: inherit;
  display: block;
  width: 100%;
`;

const Wrapper = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  height: 100%;
  flex-direction: row;
  position: relative;

  @media ${theme.breakpoints.max} {
    flex-direction: column;
  }

  &:hover {
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};

    ${CardArrow} {
      ${redVariantHoverStyles}
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.md} ${theme.spacing.md} ${theme.spacing.xs}
    ${theme.spacing.md};
  max-width: 348px;

  @media ${theme.breakpoints.max} {
    flex: 1;
    padding: ${theme.spacing.md} ${theme.spacing.xs} ${theme.spacing.xs};
    order: 2;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  position: relative;
  @media ${theme.breakpoints.max} {
    max-height: 249px;
    order: 1;
  }
`;

export const NavCard = ({ eyebrow, imageUrl, title, url }) => (
  <Link href={url} passHref>
    <Clickable>
      <Wrapper>
        <Content>
          <CardEyebrow className="font--body-tiny">{eyebrow}</CardEyebrow>
          <CardTitle className="font--heading-sm">{title}</CardTitle>
          <CardArrow direction="right" variant="red" />
        </Content>
        <ImageContainer>
          <Image alt={title} layout="fill" objectFit="cover" src={imageUrl} />
        </ImageContainer>
      </Wrapper>
    </Clickable>
  </Link>
);

NavCard.propTypes = {
  eyebrow: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};
