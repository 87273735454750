import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { theme } from "@src/theme";
import { navModalAnimationDuration } from "@components/modal/NavModal";
import { navHeight } from "./styles";
import { useRouter } from "next/router";

const scrollTriggerOptions = {
  trigger: "body",
  scrub: true,
  start: "top top",
  end: navHeight
};

export const useAnimations = ({
  navVariant,
  onContentAnimationComplete,
  onContentAnimationReverseComplete
}) => {
  const headerRef = useRef();
  const { pathname } = useRouter();

  const [scrolled, setScrolled] = useState(false);
  const [contentTimeline, setContentTimeline] = useState(null);

  const reset = () => {
    gsap.set(headerRef.current, {
      clearProps: "all"
    });
    setScrolled(false);
  };

  useEffect(() => {
    let menuHoverAnimation;
    let menuScrollAnimation;

    reset();

    if (navVariant === "light") {
      // setup menu animations for when the user hovers to open the menu
      menuHoverAnimation = gsap
        .timeline({
          onComplete: onContentAnimationComplete,
          onReverseComplete: onContentAnimationReverseComplete,
          paused: true
        })
        .to(headerRef.current, {
          "--button-background-color": theme.colors.black,
          "--button-text-color": theme.colors.white,
          "--text-color": theme.colors.black,
          duration: navModalAnimationDuration
        });

      setContentTimeline(menuHoverAnimation);

      // animate menu content on scroll
      menuScrollAnimation = gsap
        .timeline({
          onComplete: onContentAnimationComplete,
          onReverseComplete: onContentAnimationReverseComplete,
          scrollTrigger: scrollTriggerOptions
        })
        .to(headerRef.current, {
          "--button-background-color": theme.colors.black,
          "--button-text-color": theme.colors.white,
          "--text-color": theme.colors.black,
          duration: navModalAnimationDuration
        });
    }

    // animate background on scroll
    const backgroundOpacityAnimation = gsap.to(headerRef.current, {
      duration: navModalAnimationDuration,
      "--background-opacity": 1,
      scrollTrigger: {
        ...scrollTriggerOptions,
        onUpdate: (instance) => {
          setScrolled(instance.progress !== 0);
        }
      }
    });

    return () => {
      backgroundOpacityAnimation.kill();

      if (menuHoverAnimation) {
        menuHoverAnimation.pause(0).kill();
      }

      if (menuScrollAnimation) {
        menuScrollAnimation.pause(0).kill();
      }
    };
  }, [
    navVariant,
    onContentAnimationComplete,
    onContentAnimationReverseComplete,
    pathname
  ]);

  return {
    headerRef,
    reverseContentAnimation: () => contentTimeline && contentTimeline.reverse(),
    scrolled,
    startContentAnimation: () => contentTimeline && contentTimeline.play()
  };
};
