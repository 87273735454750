import styled from "styled-components";

export const MenuList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 24px 24px 0;
`;

export const MenuLink = styled.a`
  ${({ theme }) => theme.fonts.bodyLarge}
  color: ${({ theme }) => theme.colors.black};
  display: block;
  font-weight: 700;
  padding: 16px 0;
`;
