import { theme } from "@src/theme";
import PropTypes from "prop-types";

export const DiagonalArrow = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.2427 4.49997L4.24268 15.5" stroke={theme.colors.red} />
    <path d="M15.2426 12.9852V4.49996H6.75736" stroke={theme.colors.red} />
  </svg>
);

DiagonalArrow.propTypes = {
  className: PropTypes.string
};
