import PropTypes from "prop-types";
import Link from "next/link";
import styled from "styled-components";
import { DiagonalArrow as BaseDiagonalArrow } from "../DiagonalArrow";
import { MenuList as BaseMenuList } from "./Menu";
import { MenuLink } from "./Menu";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const MenuList = styled(BaseMenuList)`
  background-color: ${({ theme }) => theme.colors.creamLight};
`;

const SpecList = styled.ul`
  background-color: ${({ theme }) => theme.colors.cream};
  border-bottom: 1px solid ${({ theme }) => theme.colors.cream};
  border-top: 1px solid ${({ theme }) => theme.colors.cream};
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  list-style: none;
  margin: 24px 0 0;
  padding: 0;
`;

const Spec = styled.li`
  background-color: ${({ theme }) => theme.colors.white};
`;

const SpecLink = styled.a`
  ${({ theme }) => theme.fonts.bodySmall};
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  padding: 20px 24px;
`;

const DiagonalArrow = styled(BaseDiagonalArrow)`
  margin-left: 16px;
`;

export const CapabilitiesMenu = ({ content, onSelectItem }) => {
  return (
    <Container>
      <MenuList>
        {content.items.map((item) => (
          <li key={item.url}>
            <Link href={item.url} passHref>
              <MenuLink onClick={onSelectItem}>{item.title}</MenuLink>
            </Link>
          </li>
        ))}
      </MenuList>
      <SpecList>
        {content.specs.map((spec) => (
          <Spec key={spec.url}>
            <Link href={spec.url} passHref>
              <SpecLink rel="noreferrer" target="_blank">
                <span>{spec.name}</span>
                <DiagonalArrow />
              </SpecLink>
            </Link>
          </Spec>
        ))}
      </SpecList>
    </Container>
  );
};

CapabilitiesMenu.propTypes = {
  content: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired
      })
    ).isRequired,
    specs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  onSelectItem: PropTypes.func.isRequired
};
